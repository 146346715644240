<template>
    <div>
        <p>
            Définissez ici les différentes gammes de prestation de personnalisation proposées aux acquéreurs; Des pré sélections par gamme, type de lot, type de pièce et type de prestation (revêtements, équipements) à définir permettent d'accélérer la définition des éventails de choix proposés aux acquéreurs en fonction de leur acquisition et du programme concerné.
        </p>

        <v-row class="pb-1">
            <v-col cols="2">
                <v-card class="d-flex flex-column pa-2 fill-height">
                    <div class="">
                        <v-text-field v-model="quickSearch" class="mb-2" label="Recherche" color="app-blue" outlined dense hide-details />

                        <ProgramAutocomplete v-model="programsFilter" class="mb-2" label="Programmes" multiple />
                    </div>

                    <v-divider />

                    <v-list class="flex-grow-1" dense rounded>
                        <v-list-item-group v-model="productLineSelected">
                            <v-list-item v-for="(productLine, i) of productLines" :key="i" :value="productLine" link>
                                <v-list-item-content>
                                    <v-list-item-title> {{ productLine.label }} </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>

                    <v-divider />

                    <v-card-actions class="d-flex justify-center pa-0 pt-2">
                        <SOTProductLineAddDialog>
                            <template v-slot:activator="{ on }">
                                <v-btn class="mr-2" color="app-blue" depressed small dark v-on="on">
                                    <v-icon x-small left> fas fa-plus </v-icon>
                                    Ajouter
                                </v-btn>
                            </template>
                        </SOTProductLineAddDialog>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col>
                <SOTProductLine v-if="productLineSelected" :productLine="productLineSelected" />

                <v-card v-if="!productLineSelected" class="fill-height d-flex align-center justify-center">
                    <h1 class="grey--text"> Aucune gamme de prestation est sélectionné </h1>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import SOTProductLine from './SOTProductLine.vue';
import ProgramAutocomplete from '../../widgets/ProgramAutocomplete.vue';
import SOTProductLineAddDialog from './SOTProductLineAddDialog.vue';

export default {
    name: 'SOTProductLines',

    components: {
        SOTProductLine,
        ProgramAutocomplete,
        SOTProductLineAddDialog
    },

    data: () => ({
        productLineSelected: null,

        productLines: [],
        productLinesCount: 0,
        productLinesTotalPages: 1,

        quickSearch: '',
        programsFilter: []
    }),

    watch: {
        productLineSelected() {
            // console.log('ok');
        }
    },

    methods: {
        async fetchProductLines() {
            try {
                this.setLoading(true);

                const query = { include: 'programs,presets' };
                const { productLines, count, totalPages, err } = await this.repos.sot.getSOTProductLines(query);
                if (err) {
                    throw new Error(err);
                }
                this.productLines = productLines;
                this.productLinesCount = count;
                this.productLinesTotalPages = totalPages;
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du chargement des games de prestations',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    created() {
        this.fetchProductLines();
    }
};
</script>